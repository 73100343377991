import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { SwiperDirective, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { forkJoin, Subscription } from 'rxjs';
import { Banner } from 'src/app/models/banner.model';
import { Categoria } from 'src/app/models/categoria.model';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';
import { Parceiro } from 'src/app/models/parceiro.model';
import { Sobre } from 'src/app/models/sobre.model';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  bannerConfig: SwiperConfigInterface = {
    slidesPerView: 1,
    autoplay: {
      delay: 4000
    },
    navigation: {
      prevEl: '.banner-arrows__item--prev',
      nextEl: '.banner-arrows__item--next'
    },
    pagination: false
  };
  bannerIndex: number = 0;

  subscription: Subscription;

  banners: Banner[] = [];
  sobre: Sobre = new Sobre();
  categorias: Categoria[] = [];
  parceiros: Parceiro[] = [];

  parceirosConfig: SwiperConfigInterface = {
    slidesPerView: 'auto',
    navigation: false,
    pagination: false,
    spaceBetween: 100,
    autoplay: {
      delay: 3000,
      stopOnLastSlide: false,
    }
  };
  parceirosIndex: number = 0;

  constructor(
    public global: GlobalService,
    public _data: DataService,
  ) { }

  ngOnInit(): void {
    this.fork();
  }

  fork() {
    const reqs = [
      this._data.getBanners(-99, -99),
      this._data.getSobre(),
      this._data.getCategorias(-99, -99),
      this._data.getParceiros(-99, -99)
    ]
    this.subscription = forkJoin(reqs)
      .subscribe((res: any) => {
        this.banners = res[0];
        this.sobre = res[1];
        this.categorias = res[2];
        this.parceiros = res[3].concat(res[3]);
      })
  }

}

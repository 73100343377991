<section class="header-interno">
    <img [src]="background" [alt]="title"
        class="header-interno__image">
    <div class="container">
        <div class="header-interno-wrapper">
            <h1 class="header-interno__title">
                {{title}}
            </h1>
        </div>
    </div>
</section>
import { Component, OnInit } from '@angular/core';
import { ContatoGeral } from 'src/app/models/contato-geral.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {

  contatoGeral: ContatoGeral = new ContatoGeral();

  constructor(
    public _data: DataService,
  ) { }

  ngOnInit(): void {
    this._data.getContatoGeral().subscribe(res => this.contatoGeral = res);
  }

}

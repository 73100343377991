import { GlobalService } from './global.service';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError, Subject } from 'rxjs';
import { map, catchError, publishReplay, refCount } from 'rxjs/operators';
import { NotificationService } from './notification.service';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  httpStatus = new Subject<number>();
  httpError = new Subject<any>();

  constructor(
    public http: HttpClient,
    public global: GlobalService,
    public _notification: NotificationService
  ) { }

  get(url: string, params = {}) {
    return this.http.get(this.global.apiUrl + url, {
      params: params
    }).pipe(
      map(r => r),
      publishReplay(1), // this tells Rx to cache the latest emitted
      refCount(),
      catchError(this.handleError.bind(this))
    );

  }

  post(url: string, body = {}, params = {}) {
    return this.http.post(this.global.apiUrl + url, body, {
      params: params
    }).pipe(
      map(r => r),
      catchError(this.handleError.bind(this))
    );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error) {
      this.httpError.next(error.error);
    }
    if (error.status) {
      if (error.status == 500) {
        this._notification.showError(error.error)
      }
      this.httpStatus.next(error.status);
    }
    return throwError(error);
  }
}

import { Component, OnInit } from '@angular/core';
import { LinhaDoTempo } from 'src/app/models/linha-do-tempo.model';
import { Sobre } from 'src/app/models/sobre.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-sobre',
  templateUrl: './sobre.component.html',
  styleUrls: ['./sobre.component.scss']
})
export class SobreComponent implements OnInit {

  sobre: Sobre = new Sobre();
  linhas: LinhaDoTempo[] = [];

  constructor(
    public _data: DataService,
  ) { }

  ngOnInit(): void {
    this.getSobre();
    this.getLinhaDoTempo();
  }

  getSobre() {
    this._data.getSobre().subscribe(res => this.sobre = res);
  }

  getLinhaDoTempo() {
    this._data.getLinhaDoTempo(-99, -99).subscribe(res => this.linhas = res);
  }

}

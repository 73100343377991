<app-header-interno [title]="categoria.nome" [background]="global.imageUrl+categoria.imagem"></app-header-interno>

<section class="produtos">
    <div class="container">
        <div class="wrapper">
            <div class="produtos-section" *ngFor="let s of subcategorias; let i = index;">
                <div class="produtos-linhas">
                    <button class="produtos-linhas__item"
                        [style.background-image]="'url(' + global.imageUrl+s.imagem + ')'">
                        <h2>{{s.nome}}</h2>
                    </button>
                </div>
                <div class="produtos-list">
                    <div class="produtos-item" *ngFor="let p of s.produtos; let i = index;">
                        <button class="produtos-item__header" (click)="p.active = !p.active" [class.open]="p.active">
                            <h3>{{p.nome}}</h3>
                            <i class="fas fa-chevron-down" *ngIf="!p.active"></i>
                            <i class="fas fa-chevron-up" *ngIf="p.active"></i>
                        </button>
                        <div class="produtos-item__content" [class.open]="p.active">
                            <div class="content">
                                <h3>{{p.nome}}</h3>
                                <h4>
                                    <span>Aplicação:</span>
                                    {{p.aplicacao}}
                                </h4>
                                <h4>
                                    <span>Característica:</span>
                                    {{p.caracteristica}}
                                </h4>
                                <div class="produtos-item__description" [innerHtml]="p.descricao"></div>
                            </div>
                        </div>
                    </div>
                    <button class="btn btn-default block" type="button" (click)="openModalBudget(s)">
                        ENVIAR ORÇAMENTO
                    </button>
                </div>
            </div>
        </div>
    </div>
</section>
<app-modal-budget [active]="modalActive" [subcategoria]="subcategoria" (eventEmitter)="modalActive = $event"></app-modal-budget>
import { Component, OnInit } from '@angular/core';
import { ContatoGeral } from 'src/app/models/contato-geral.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-whatsapp-button',
  templateUrl: './whatsapp-button.component.html',
  styleUrls: ['./whatsapp-button.component.scss']
})
export class WhatsappButtonComponent implements OnInit {

  active: boolean = false;
  contatoGeral: ContatoGeral = new ContatoGeral();

  constructor(
    public _data: DataService,
  ) { }

  ngOnInit(): void {
    this._data.getContatoGeral().subscribe(res => this.contatoGeral = res);
  }

  sendMessage(string: string) {
    let msg = string.replace(/ /g, "%20");
    window.open(`https://wa.me/55${this.contatoGeral.whatsApp}?text=${msg}`, '_blank');
  }

}

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Banner } from '../models/banner.model';
import { Categoria } from '../models/categoria.model';
import { ContatoGeral } from '../models/contato-geral.model';
import { ContatoMensagem } from '../models/contato-mensagem.model';
import { LinhaDoTempo } from '../models/linha-do-tempo.model';
import { Parceiro } from '../models/parceiro.model';
import { Sobre } from '../models/sobre.model';
import { Subcategoria } from '../models/subcategoria.model';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(
    public api: ApiService
  ) { }

  getContatoGeral(): Observable<ContatoGeral> {
    return this.api.get(`/site/contatoGeral/buscar`)
  }

  getBanners(numeroPagina: number, registroPorPagina: number): Observable<Banner[]> {
    return this.api.get('/site/banner/buscar', {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
    });
  }

  getSobre(): Observable<Sobre> {
    return this.api.get('/site/sobre/buscar');
  }

  getCategorias(numeroPagina: number, registroPorPagina: number): Observable<Categoria[]> {
    return this.api.get('/site/categoria/buscar', {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
    });
  }

  getCategoria(slug: string): Observable<Categoria> {
    return this.api.get(`/site/categoria/buscar`, { slug: slug });
  }

  getSubcategorias(idCategoria: number): Observable<Subcategoria[]> {
    return this.api.get(`/site/subcategoria/buscar`, { idCategoria: idCategoria });
  }

  getSubcategoria(id: number): Observable<Subcategoria> {
    return this.api.get(`/subcategoria/buscar`, { id: id });
  }

  getParceiros(numeroPagina: number, registroPorPagina: number): Observable<Parceiro[]> {
    return this.api.get('/site/parceiro/buscar', {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
    });
  }

  getLinhaDoTempo(numeroPagina: number, registroPorPagina: number): Observable<LinhaDoTempo[]> {
    return this.api.get('/site/linhaDoTempo/buscar', {
      numeroPagina: numeroPagina,
      registroPorPagina: registroPorPagina,
    });
  }

  inserirContatoMensagem(mensagem: ContatoMensagem): Observable<any> {
    return this.api.post(`/site/contatoMensagem/inserir`, mensagem);
  }

}

<header class="header" [class.active]="active">
    <nav class="header-nav">
        <a class="logo" routerLink="/">
            <img src="assets/logo.png" alt="Lubsul Distribuidor">
        </a>
        <button class="hamburger hamburger--spin header-menu-toggle hidden-desktop" [class.is-active]="menuToggle"
            (click)="menuToggle = !menuToggle" type="button">
            <span class="hamburger-box">
                <span class="hamburger-inner"></span>
            </span>
        </button>
        <ul class="header-menu" [class.active]="menuToggle">
            <li class="header-menu__item">
                <a class="header-menu__link" routerLink="/" (click)="menuToggle = false">
                    Home
                </a>
            </li>
            <li class="header-menu__item">
                <a class="header-menu__link" routerLink="/sobre" (click)="menuToggle = false">
                    Sobre
                </a>
            </li>
            <li class="header-menu__item">
                <a class="header-menu__link">
                    Linha de produtos
                </a>
                <div class="submenu">
                    <a class="submenu-item" *ngFor="let c of categorias" [routerLink]="['/produtos', c.slug]"
                        routerLinkActive="active" (click)="menuToggle = false">{{c.nome}}</a>
                </div>
            </li>
            <li class="header-menu__item">
                <a class="header-menu__link" (click)="menuToggle = false" routerLink="/contato">
                    Contato
                </a>
            </li>
            <li class="header-menu__item">
                <a class="header-menu__link" (click)="menuToggle = false">
                    <i class="fab fa-facebook-square"></i>
                </a>
                <a class="header-menu__link" (click)="menuToggle = false">
                    <i class="fab fa-instagram"></i>
                </a>
            </li>
            <li class="header-menu__item">
                <a class="btn btn-default" target="_blank" href="https://admin.lubsuldistribuidor.com.br">
                    ÁREA RESTRITA
                </a>
            </li>
        </ul>
        <div class="header-menu-backdrop hidden-desktop" [class.active]="menuToggle" (click)="menuToggle = false"></div>
    </nav>
</header>
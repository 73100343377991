import { Component, OnInit, HostListener } from '@angular/core';
import { Categoria } from 'src/app/models/categoria.model';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  active: boolean = false;
  menuToggle: boolean = false;

  categorias: Categoria[] = [];

  constructor(
    public _data: DataService
  ) { }

  @HostListener('window:scroll', [])
  handleScroll(): void {
    const windowScroll = window.pageYOffset;
    if (windowScroll >= 50) {
      this.active = true;
    } else {
      this.active = false;
    }
  }

  ngOnInit(): void {
    this.buscarCategorias();
  }

  buscarCategorias() {
    this._data.getCategorias(-99, -99).subscribe(res => this.categorias = res);
  }

}

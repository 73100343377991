<app-header-interno [title]="'Sobre'" [background]="'assets/bg-sobre.jpg'"></app-header-interno>

<section class="sobre">
    <div class="container">
        <div [innerHtml]="sobre.sobre"></div>
        <div class="sobre-description">
            <div class="sobre-description__item">
                <h3>
                    Missão
                </h3>
                <hr>
                <p>
                    {{sobre.missao}}
                </p>
            </div>
            <div class="sobre-description__item">
                <h3>
                    Visão
                </h3>
                <hr>
                <p>
                    {{sobre.visao}}
                </p>
            </div>
        </div>
    </div>
</section>

<div class="timeline">
    <div class="timeline-box">
        <div class="timeline__title">
            <h3>LINHA DO TEMPO</h3>
            <hr>
        </div>
        <div class="timeline-list">
            <div class="timeline-item" *ngFor="let linha of linhas;">
                <h4 class="timeline-item__title">
                    {{linha.titulo.substr(0, 2)}}<b>{{linha.titulo.substr(-2)}}</b>
                </h4>
                <p class="timeline-description">
                    {{linha.descricao}}
                </p>
            </div>
        </div>
    </div>
</div>
import { LOCALE_ID, NgModule } from '@angular/core';
// imports
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';
import { InlineSVGModule } from 'ng-inline-svg';
import { NgxMaskModule } from 'ngx-mask';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { ToastrModule } from 'ngx-toastr';
// swiper
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
// components
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { WhatsappButtonComponent } from './components/whatsapp-button/whatsapp-button.component';
// pages
import { HomeComponent } from './pages/home/home.component';
import { MapsGoogleComponent } from './components/maps-google/maps-google.component';
import { SobreComponent } from './pages/sobre/sobre.component';
import { HeaderInternoComponent } from './components/header-interno/header-interno.component';
import { ProdutosComponent } from './pages/produtos/produtos.component';
import { AlertComponent } from './components/alert/alert.component';
import { ModalBudgetComponent } from './components/modal-budget/modal-budget.component';
import { ContatoComponent } from './pages/contato/contato.component';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    WhatsappButtonComponent,
    MapsGoogleComponent,
    SobreComponent,
    HeaderInternoComponent,
    ProdutosComponent,
    AlertComponent,
    ModalBudgetComponent,
    ContatoComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SwiperModule,
    InlineSVGModule.forRoot(),
    NgxMaskModule.forRoot(),
    ScrollToModule.forRoot(),
    ToastrModule.forRoot({
      timeOut: 3500,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
      iconClasses: {
        error: 'toast-error',
        info: 'toast-info',
        success: 'toast-success',
        warning: 'toast-warning',
      }
    }),
  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    },
    { provide: LOCALE_ID, useValue: 'pt-BR' },
  ],
  entryComponents: [
    MapsGoogleComponent,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Categoria } from 'src/app/models/categoria.model';
import { Produto } from 'src/app/models/produto.model';
import { Subcategoria } from 'src/app/models/subcategoria.model';
import { DataService } from 'src/app/services/data.service';
import { GlobalService } from 'src/app/services/global.service';

@Component({
  selector: 'app-produtos',
  templateUrl: './produtos.component.html',
  styleUrls: ['./produtos.component.scss']
})
export class ProdutosComponent implements OnInit {

  categoria: Categoria = new Categoria();
  subcategorias: Subcategoria[] = [];
  subcategoria: Subcategoria = new Subcategoria();
  modalActive: boolean = false;

  constructor(
    public _data: DataService,
    public route: ActivatedRoute,
    public global: GlobalService,
  ) { }

  ngOnInit(): void {

    this.route.params.subscribe(param => this.getCategoria(param.slug))
  }

  getCategoria(slug: string) {
    this._data.getCategoria(slug)
      .subscribe(res => {
        this.categoria = res;
        this.getSubcategorias(res.id);
      });
  }

  getSubcategorias(idCategoria: number) {
    this._data.getSubcategorias(idCategoria)
      .subscribe(res => this.subcategorias = res);
  }

  openModalBudget(subcategoria: Subcategoria) {
    this.subcategoria = subcategoria;
    this.modalActive = true;
  }

}

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class GlobalService {

  apiUrl: string = 'https://api.lubsuldistribuidor.com.br';
  imageUrl: string = 'https://api.lubsuldistribuidor.com.br/';
  modalRetryPassword = new Subject<boolean>();

  constructor() { }


}

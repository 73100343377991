<app-header-interno [title]="'Contato'" [background]="'assets/banner-contato.jpeg'"></app-header-interno>

<section class="contato">
    <div class="container">
        <div class="contato-wrapper">
            <div class="contato-item">
                <div class="contato-item__icon">
                    <i class="fas fa-phone-alt"></i>
                </div>
                <a [href]="'tel:'+contatoGeral.telefone">
                    {{contatoGeral.telefone}}
                </a>
            </div>
            <div class="contato-item">
                <div class="contato-item__icon">
                    <i class="fas fa-envelope"></i>
                </div>
                <a [href]="'mailto:'+contatoGeral.email">
                    {{contatoGeral.email}}
                </a>
            </div>
            <div class="contato-item">
                <div class="contato-item__icon">
                    <i class="fas fa-map-marker-alt"></i>
                </div>
                <span>
                    {{contatoGeral.rua}}, {{contatoGeral.numeroEndereco}} - {{contatoGeral.bairro}},
                    {{contatoGeral.cidade}} - {{contatoGeral.uf}}
                </span>
            </div>
        </div>
    </div>
</section>
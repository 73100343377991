<section class="modal" [class.active]="active">
    <div class="modal-header">
        <h3 class="modal-title">Orçamento: {{subcategoria.categoria.nome}} - {{subcategoria.nome}}</h3>
        <button class="modal-close" (click)="eventEmitter.emit(false)">
            <i class="fas fa-times"></i>
        </button>
    </div>
    <form class="form modal-content" (ngSubmit)="submit(form)" #form="ngForm">
        <div class="form-control">
            <div class="form-control__icon">
                <div class="svg-icon" aria-label="Nome" [inlineSVG]="'assets/icons/account.svg'"></div>
            </div>
            <input type="text" name="nome" placeholder="Nome completo" autocomplete="name"
                [(ngModel)]="contatoMensagem.nome" #nome="ngModel" required>
            <i class="fas fa-exclamation-circle" *ngIf="nome.invalid && (nome.touched || nome.dirty)"></i>
        </div>
        <div class="form-control">
            <div class="form-control__icon">
                <div class="svg-icon" aria-label="E-mail" [inlineSVG]="'assets/icons/mail.svg'"></div>
            </div>
            <input type="email" name="email" placeholder="E-mail" autocomplete="email"
                [(ngModel)]="contatoMensagem.email" #email="ngModel" required>
            <i class="fas fa-exclamation-circle" *ngIf="email.invalid && (email.touched || email.dirty)"></i>
        </div>
        <div class="form-control">
            <div class="form-control__icon">
                <div class="svg-icon" aria-label="Telfone" [inlineSVG]="'assets/icons/phone.svg'"></div>
            </div>
            <input type="tel" name="telefone" placeholder="Telefone" mask="(00) 0000-0000 || (00) 0 0000-0000"
                [(ngModel)]="contatoMensagem.telefone" #telefone="ngModel" required>
            <i class="fas fa-exclamation-circle" *ngIf="telefone.invalid && (telefone.touched || telefone.dirty)"></i>
        </div>
        <textarea name="mensagem" id="mensagem" placeholder="Mensagem" [(ngModel)]="contatoMensagem.mensagem"
            cols="30"></textarea>
        <button class="btn btn-default block" type="submit" [disabled]="loading">
            <i *ngIf="loading" class="fas fa-spin fa-spinner"></i>
            <span *ngIf="!loading">ENVIAR</span>
        </button>
    </form>
</section>
<div class="modal-backdrop" [class.active]="active" (click)="eventEmitter.emit(false)"></div>
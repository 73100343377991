<section class="banner">
  <swiper [config]="bannerConfig" [(index)]="bannerIndex">
    <div
      class="banner-item"
      *ngFor="let b of banners"
      [style.background-image]="
        'url(' + global.imageUrl + banners[bannerIndex].imagem + ')'
      "
    >
      <div class="container">
        <div class="banner-slider__item">
          <h2 class="title">
            {{ b.titulo }}
          </h2>
          <p class="description">
            {{ b.descricao }}
          </p>
          <a *ngIf="b.link" [href]="b.link" class="link btn btn-default"
            >ver mais</a
          >
        </div>
      </div>
    </div>
  </swiper>

  <div class="banner-arrows hidden-mobile">
    <button class="banner-arrows__item banner-arrows__item--prev">
      <i class="fas fa-arrow-left"></i>
    </button>
    <button class="banner-arrows__item banner-arrows__item--next">
      <i class="fas fa-arrow-right"></i>
    </button>
  </div>
</section>

<section class="home-sobre">
  <div class="container">
    <div class="home-sobre-wrapper">
      <div class="image">
        <img [src]="global.imageUrl + sobre.imagem" alt="Sobre Imagem" />
      </div>
      <div class="content">
        <h2 class="home-sobre__title">Quem somos?</h2>
        <div class="home-sobre__description" [innerHtml]="sobre.sobre"></div>
      </div>
    </div>
    <div class="facts">
      <div class="facts-wrapper">
        <div class="facts-item">
          <i class="fas fa-calendar-alt"></i>
          <h3 class="facts-item__number">{{ sobre.valorCaracteristica1 }}</h3>
          <span class="facts-item__title">{{ sobre.caracteristica1 }}</span>
        </div>
        <div class="facts-item">
          <i class="fas fa-user-friends"></i>
          <h3 class="facts-item__number">{{ sobre.valorCaracteristica2 }}</h3>
          <span class="facts-item__title">{{ sobre.caracteristica2 }}</span>
        </div>
        <div class="facts-item">
          <i class="fas fa-user-tie"></i>
          <h3 class="facts-item__number">{{ sobre.valorCaracteristica3 }}</h3>
          <span class="facts-item__title">{{ sobre.caracteristica3 }}</span>
        </div>
        <div class="facts-item">
          <i class="fas fa-oil-can"></i>
          <h3 class="facts-item__number">{{ sobre.valorCaracteristica4 }}</h3>
          <span class="facts-item__title">{{ sobre.caracteristica4 }}</span>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="linhas">
  <div class="linha-item" *ngFor="let c of categorias; let i = index">
    <div class="container">
      <div class="linha-item-wrapper">
        <img [src]="global.imageUrl + c.imagem" [alt]="c.nome" />
        <div class="content">
          <i [class]="c.icone"></i>
          <div class="line"></div>
          <h2>
            <span>LINHA</span>
            {{ c.nome }}
          </h2>
        </div>
        <a
          [routerLink]="['/produtos', c.slug]"
          class="btn btn-default btn-large"
        >
          VER MAIS
        </a>
      </div>
    </div>
  </div>
</section>

<section class="partners">
  <div class="container">
    <div class="partners-wrapper">
      <div class="partners__title title title-left">
        <h2>Nossos parceiros</h2>
        <hr />
        <p>
          A Lubsul entende que o sucesso de qualquer empresa não se faz sozinho
          e que o trabalho em parceria é fundamental para alavancar resultados
          positivos.
        </p>
      </div>
      <swiper
        [config]="parceirosConfig"
        [(index)]="parceirosIndex"
        class="partners-carousel"
      >
        <div
          class="partners-item"
          *ngFor="let p of parceiros"
          style="width: 160px"
        >
          <img [src]="global.imageUrl + p.imagem" [alt]="p.nome" />
        </div>
      </swiper>
    </div>
  </div>
</section>

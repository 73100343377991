import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { NgForm } from '@angular/forms';
import { } from 'events';
import { ContatoMensagem } from 'src/app/models/contato-mensagem.model';
import { Subcategoria } from 'src/app/models/subcategoria.model';
import { AlertService } from 'src/app/services/alert.service';
import { DataService } from 'src/app/services/data.service';
import { HelperService } from 'src/app/services/helper.service';

@Component({
  selector: 'app-modal-budget',
  templateUrl: './modal-budget.component.html',
  styleUrls: ['./modal-budget.component.scss']
})
export class ModalBudgetComponent implements OnInit {

  @Input('active') active: boolean;
  @Input('subcategoria') subcategoria: Subcategoria;
  @Output() eventEmitter = new EventEmitter<boolean>();

  contatoMensagem: ContatoMensagem = new ContatoMensagem();
  loading: boolean = false;

  constructor(
    public helper: HelperService,
    public _data: DataService,
    public _alert: AlertService,
  ) { }

  ngOnInit(): void {
  }

  submit(f: NgForm) {

    if (f.invalid) {
      this.helper.formMarkAllTouched(f);
      return;
    }

    this.loading = true;

    this.contatoMensagem.assunto = `Orçamento: ${this.subcategoria.categoria.nome} - ${this.subcategoria.nome}`;

    this._data.inserirContatoMensagem(this.contatoMensagem)
      .subscribe((res: any) => {
        this._alert.present('Mensagem enviada', 'Em breve entraremos em contato.');
        f.reset();
        this.loading = false;
        this.eventEmitter.emit(false);
      }, e => {
        this._alert.present('Não foi possível enviar a mensagem!', 'Desculpe, mas não foi possível enviar a mensagem. Tente novamente outra hora.');
        this.loading = false;
      })
  }

}
